"use client";

import { type PropsWithChildren } from "react";

const LoginTemplate = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <main>
      <div className="flex h-screen w-full bg-charcoal_50 items-center justify-center">
        {children}
      </div>
    </main>
  );
};

export default LoginTemplate;
